import React from 'react';
import { css } from '@emotion/react';
import { StaticQuery, graphql } from 'gatsby';
import { wrapper, TitleHeader, IconButton } from '../../components/defaults/styles/elements';
import mq from '../../components/defaults/styles/mediaquerys';
import { color } from '../defaults/styles/theme';
import Info from '../../components/defaults/assets/svg/info.svg';
import Mail from '../../components/defaults/assets/svg/mail.svg';
import Phone from '../../components/defaults/assets/svg/phone.svg';
import Flags from '../../data/raw/flags';
import { People } from '../../components/defaults/assets/svg/mobilenav';
import BackgroundImage from 'gatsby-background-image-es5';


const ButtonStyle = css`
  z-index: 1;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1em;
  &:before {
    background: ${color.accent_light};
  }
`

const PersonCard = ({data}) => {
  const person = data.data;
  return (
    <div css={css`
      font-size: .75em;
      flex: 1 0 100%;
      display: flex;
      flex-direction: column;
      :not(:last-of-type) {
        margin-bottom: 3em;
      }
      ${mq[1]} {
        flex: 1 0 calc(50% - .75em);
        :not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 1.5em;
        }
      }
    `}>
      <p css={css`
        color: white;
        padding: 0 2em;
        margin-bottom: 1em;
        max-width: 25rem;
      `}>{ person.name === "Hannah Boomgaarden" ? (
        "Questions about EXPO 9, its program, structure or the STARTUP AUTOBAHN itself? Get in touch with:"
      ) : person.name === "Bastian Kroggel" ? (
        "For technical inqueries, questions about the website, or to request content changes reach out to:"
      ) : ''}</p>
      <div css={css`
        background: ${color.main_light};
        border-radius: 5px;
        padding: 2em;
        display: flex;
        align-items: stretch;
        flex: 0 1 100%;
        p {
          margin-bottom: 0;
        }
      `}>
        <div css={css`
          width: 110px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 2em;
        `}>
          <BackgroundImage fluid={person.image.localFiles[0].childImageSharp.fluid} css={css`
              width: 100%;
              height: 100%;
          `} />
        </div>
        <div css={css`
          padding-top: 1em;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}>
          <div>
            <p css={css`
              font-size: .85rem;
            `}><b>{person.name}</b></p>
            <p>{person.profession}</p>
            <p>{person.partner_company[0].data.name}</p>
            {person.phone_number ? <a css={css`
              color: ${color.main_dark};
              margin-top: .75em;
              display: inline-block;
              text-decoration: none;
              svg {
                height: 1em;
                width: auto;
                margin-right: .5em;
              }
            `} href={"tel:" + person.phone_number}><Phone /> {person.phone_number}</a> : ""}
          </div>
  
          <div css={css`
            margin-top: 2em;
            * {
              margin-right: 1em;
            }
          `}>
            {person.email && person.consent ? (
              <IconButton white name="Send EMail" url={`mailto:${person.email}`} icon={<Mail />} css={ButtonStyle} />
              ) : (
                ""
              )
            }
            {Flags.features.people === true || Flags.features.people === "profile_only" ? (
              <IconButton white name="Profile" url={`/people/${data.recordId}`} icon={<People />} css={ButtonStyle} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ContactPersons = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allAirtable(filter: {recordId: {in: ["recqrU5LwY1kZFR6o", "recv0NzMm1ubhflvo"]}}, sort: {fields: data___surename, order: ASC}) {
            edges {
              node {
                recordId
                data {
                  name
                  mail_public
                  profession
                  consent
                  email
                  phone_number
                  partner_company {
                    data {
                      name
                    }
                  }
                  image {
                    localFiles {
                      childImageSharp {
                        fluid(grayscale: true) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => 
        data.allAirtable.edges.map((person, index) => (
          <PersonCard data={person.node} key={index} />
        ))}
    ></StaticQuery>
  )
}

const ContactContent = () => {

  return (
    <>
      <div css={[wrapper, css`
        color: white;
      `]}>
        <TitleHeader title="Get in touch" subtitle="Still not sure what EXPO 9 is all about or questions about the program and its processees? – Do not hesitate to get in touch – we are here to help." icon={<Info />} dark inline transparent />
      </div>
      <div css={[wrapper, css`
        display: flex;
        margin-top: 2rem;
        flex-direction: column;
        ${mq[1]} {
          flex-direction: row;
        }
      `]}>
        <ContactPersons />
      </div>
    </>
  )
};



const Contact = () => {
  return (
    <section id="contact" css={[css`
       padding-bottom: 8rem;
       padding-top: 3rem;
       background: ${color.main_dark};
    `]}>
      <ContactContent />
    </section>
  )
}

export default Contact;